.toggle {
    padding: 4px;
    border: 1px solid var(--primary-color);
    border-radius: 22px;
    background-color: var(--primary-color);
    width: fit-content;
    user-select: none;
    color: white;
}

.toggleContainer {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 20px;
    background: var(--primary-color);
    cursor: pointer;
    margin: auto;
}

.toggleContainer::before {
    content: '';
    position: absolute;
    width: 50%;
    height: 100%;
    left: 0%;
    border-radius: 16px;
    background-color: var(--secondary-color);
    transition: all 0.3s;
}

.toggleCheckbox:checked+.toggleContainer::before {
    left: 50%;
}

.toggleContainer div {
    padding: 6px 16px;
    text-align: center;
    z-index: 1;
}

.toggleCheckbox {
    display: none;
}

.toggleCheckbox:checked+.toggleContainer div:first-child {
    transition: color 0.3s;
}

.toggleCheckbox:checked+.toggleContainer div:last-child {
    transition: color 0.3s;
}

.toggleCheckbox+.toggleContainer div:first-child {
    transition: color 0.3s;

}

.toggleCheckbox+.toggleContainer div:first-child img {
    -webkit-filter: invert(100%);
    filter: invert(100%);
}


.toggleCheckbox+.toggleContainer div:last-child {
    transition: color 0.3s;
}