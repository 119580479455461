.overview-text {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  word-wrap: break-word;
}

.overview-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.overview-description {
  margin-top: 10px;
  margin-bottom: 20px; 
  max-width: 100%;
}

.dataset-info a {
  color: blue;
  text-decoration: underline;
}
