.footer {
    position: absolute;
    bottom: 0;
    height: var(--footer-height);
    left: 0;
    width: 100%;

}


.footer .logos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50%;
}

.footer .logos .logo {
    display: flex;
    align-items: center;
    height: 100%;
    padding-bottom: calc(var(--padding) / 2);
    box-sizing: border-box;
}

.footer .logos .logo img {
    max-height: 100%;
    max-width: 100%;
}

.footer .logos .logo.eth img {
    width: 150px;
    height: auto;
}

.footer .logos .logo.c2sm img {
    width: 95px;
    height: auto;
}

.footer .logos .logo.eawag img {
    width: 140px;
    height: auto;
}
