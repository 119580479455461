.header {
    height: var(--header-height);
    position: relative;
    display: flex;
    align-items: center;
}

.header .logo {
    height: 100%;
    display: flex;
    align-items: center;
}

.header .logo img {
    height: 100%;
    padding: var(--padding) 0;
    box-sizing: border-box;
    margin-right: 10px
}

.header .title {
    display: inline-block;
    padding: var(--padding);
    vertical-align: top;
    font-size: 30px;
    line-height: 50px;
}

@media screen and (max-width: 650px) {
    .header .title {
        display: none;
    }
  }