.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 40px;
  border-radius: 10px;
  text-align: center;
  z-index: 1001;
  width: 50%;
  max-width: 600px;
  font-size: 1.2rem;
}

.modal-content p {
  margin: 0 0 20px;
  font-size: 1.5rem;
}

.modal-content a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.modal-content a:hover {
  text-decoration: underline;
}

.modal-content button {
  padding: 15px 30px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
}

.modal-content button:hover {
  background-color: #0056b3;
}
