/* Master CSS */


/* Fonts */

@font-face {
  font-family: "afacad";
  src: url("fonts/Afacad.ttf") format("truetype-variations");
  font-weight: 1 999;
}

:root {
  --background-color: #FFFFFF;
  --primary-color: #202046;
  --secondary-color: #3886CE;
  --accent-color: #6ee2f5;
  --border-color: #d2d7d8;
  --padding: 30px;
  --box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 6px;
}

:root {
  --header-height: calc(50px + var(--padding) * 2);
  --footer-height: 50px;
}

@media screen and (max-width: 800px) {
  :root {}
}

#root {
  background-color: var(--background-color);
}

.main {
  width: 100%;
  max-width: 1000px;
  margin: auto;
  min-height: 100vh;
  padding: 0 calc(var(--padding) / 2) var(--footer-height);
  box-sizing: border-box;
  font-family: "afacad", "Arial", sans-serif;
  position: relative;
}

.main .content .section {
  padding: calc(var(--padding) / 2) 0;
  font-size: 22px;
}

.main .content .datasets {
  margin-bottom: calc(var(--padding)/2);
}

.main .content .datasets .dataset {
  background-color: var(--primary-color);
  color: var(--background-color);
  padding: 6px 12px;
  border-radius: 12px;
  display: inline-block;
  cursor: pointer;
  margin: 0px 10px 10px 0px;
}

.main .content .datasets .dataset.active {
  background-color: var(--secondary-color);
}

.main .content .datasets .dataset:hover {
  background-color: var(--secondary-color);
}

.main .content .progressive-dropdowns .dropdown {
  margin-bottom: 12px;
}

.main .content .progressive-dropdowns .dropdown select {
  min-width: 100px;
  display: block;
  padding: 6px 12px;
  border-radius: 12px;
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  background-color: var(--background-color);
}

.main .content .progressive-dropdowns .date-input {
  display: inline-block;
  max-width: 50%;
  padding-right: var(--padding);
  margin-bottom: 12px;
  box-sizing: border-box;
}

.main .content .progressive-dropdowns .date-input input {
  min-width: 100px;
  display: block;
  padding: 6px 12px;
  border-radius: 12px;
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  background-color: var(--background-color);
}

.main .content .select {
  padding: calc(var(--padding) / 2) 0;
}

.main .content .select .input {
  display: inline-block;
  max-width: 50%;
  padding-right: var(--padding);
  margin-bottom: 12px;
  box-sizing: border-box;
}

.main .content .select .input input {
  display: block;
  padding: 6px 12px;
  border-radius: 12px;
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  width: 100%;
}

.main .content .download {
  display: none;
}

.main .content .select-map {
  width: 100%;
  height: 400px;
}

.main .content .request-box {
  border: 1px dashed green;
  padding: var(--padding);
  border-radius: 12px;
  margin-bottom: calc(var(--padding)/2);
  position: relative;
}

.main .content .request-box.invalid {
  border-color: red;
}

.main .content .request-box .valid {
  position: absolute;
  top: 6px;
  left: 12px;
  color: green;
}

.main .content .request-box.invalid .valid {
  color: red;
}

.main .content .copy {
  margin-bottom: calc(var(--padding)* 2);
  background-color: var(--border-color);
  color: var(--background-color);
  padding: 6px 12px;
  border-radius: 12px;
  display: inline-block;
  cursor: default;
}

.main .content .copy.enabled {
  background-color: var(--primary-color);
  cursor: pointer;
}

.main .content .copy.clicked {
  background-color: var(--secondary-color);
  cursor: default;
}