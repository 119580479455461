.instructions {
  background-color: #ffffff;
  padding: 0px;
  margin-bottom: 20px;
  border-radius: 5px;
  font-family: "afacad", "Arial", sans-serif;
}

.instructions h2 {
  margin-top: 0;
  font-size: 22px;
  margin-bottom: 15px;
}

.instructions p {
  font-size: 1.1em;
  margin-bottom: 15px;
}

.instructions ol {
  padding-left: 20px;
  font-size: 1.1em;
  line-height: 1.6
}

.instructions .additional-info {
  margin-top: 20px;
  font-size: 1.0em;
}
